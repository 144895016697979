window.addEventListener("DOMContentLoaded", () => {

    var COUNT = 0;
    var heroElements = document.querySelectorAll(".hero-block__bg-img");
    var hero = document.querySelector(".hero-block");
    var MAX_COUNT = 0;
    var ACTIVE_CLASS = "active";
    var heroInterval;

    if (hero == undefined) {
        return;
    } else {
        var MAX_COUNT = heroElements.length - 1;
        heroElements[0].classList.add(ACTIVE_CLASS);
    }

    const clickElems = hero?.querySelector('[data-click="button"]');

    clickElems?.addEventListener('click', () => {
        const btn = hero?.querySelector('.hero-block__cta .button');

        btn?.click();
    });

    function pauseAnimation() {
        if (heroInterval) {
            clearInterval(heroInterval);
        }
    }

    function runAnimation() {
        heroInterval = setInterval(function () {

            if (COUNT > MAX_COUNT) {
                COUNT = 0;
            } else {
                COUNT++;

                if (COUNT > MAX_COUNT) {
                    COUNT = 0;
                }
            }

            heroElements[COUNT].classList.add(ACTIVE_CLASS);

            if (COUNT === 0) {
                heroElements[MAX_COUNT].classList.remove(ACTIVE_CLASS);
            } else {
                heroElements[COUNT].previousElementSibling.classList.remove(ACTIVE_CLASS);
            }

        }, 6000);
    }

    runAnimation();

    hero.addEventListener('mouseenter', function () {
        pauseAnimation();
    });
    hero.addEventListener('mouseleave', function () {
        runAnimation();
    });

});